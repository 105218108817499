/* eslint-disable */
import axios from 'axios'
import { GIDEON_STORE_API_CONFIG } from '../config/gideonstore.api.config.js'

export const state = () => ({
  storeSiteCoreBaseURL: 'https://apps.gideons.org/TGIMRPWebAPI/store',
  gideonStoreLandingPageInfo: {
    name: 'Store',
    text:
      'Our standard shipping is 7-10 business days. However, 10% of our members are experiencing delays greater than 10 days. For faster shipping, choose Expedited Processing at checkout. Or Call Membership Services for other shipping options.',
    buttonScripture: 'Scripture',
    buttonMerchandise: 'Merchandise',
    rotatorImages: [],
    label_page_title: 'STORE'
  },
  gideonNavigationPageInfo: {
    name: 'Navigation',
    information:
      'Our standard shipping is 7-10 business days. However, 10% of our members are experiencing delays greater than 10 days. For faster shipping, choose Expedited Processing at checkout. Or Call Membership Services for other shipping options.',
    buttonBack: 'Back',
    buttonSearch: 'Search',
    buttonFavorites: 'Favorites',
    buttonMyOrder: 'My Orders',
    buttonCart: 'Cart',
    labelMerchTotal: 'Merchandise Total:'
  },
  gideonSubcategoryPageInfo: {
    itemId: '',
    name: 'Subcategory',
    ptc_key: '',
    description:
      'Our standard shipping is 7-10 business days. However, 10% of our members are experiencing delays greater than 10 days. For faster shipping, choose Expedited Processing at checkout. Or Call Membership Services for other shipping options.',
    category_class: '',
    category_icon_name: '',
    button_view_more: 'View More',
    button_go: 'Go',
    button_addtocart: 'ADD TO CART',
    label_product_not_available: 'Out of Stock',
    label_item: 'Item',
    label_price: 'Price',
    label_back_description: 'STORE HOME',
    label_page_title: 'store'
  },
  gideonProductDetailPageInfo: {
    itemId: '',
    name: 'Product Detail',
    description: 'Product Information',
    label_quantity: 'Quantity',
    cart_quantity: 0,
    button_AddToCart: '',
    button_AddToFavorites: '',
    button_Go: '',
    label_item: '',
    label_price: '',
    label_OtherProduct_Title: '',
    label_page_title: '',
    label_back_description: '',
    Subcategory: {
      itemId: '',
      ptc_key: '',
      category_name: ''
    },
    Product: {
      itemId: '',
      prd_key: '',
      prd_code: '',
      prd_name: '',
      prd_store_description: '',
      prd_image_file_path: '',
      prd_detail_image_file_path1: '',
      prd_detail_image_file_path2: '',
      prd_display_price: '0',
      prd_show_out_of_stock: false
    },
    OtherProducts: []
  },
  gideonFavoritesPageInfo: {
    itemId: '',
    name: 'Favorites',
    description: '',
    label_cart_title: 'Favorites',
    label_product: 'Product',
    label_item: 'Item',
    label_price: 'Price',
    label_type: 'Type',
    label_add: 'Add',
    label_page_title: 'Store',
    label_merchandise_description: 'This is Merchandise',
    label_scripture_description: 'This is Scripture',
    label_back_description: '',
    label_NoRecordsFound: 'No records were found.',
    Merchandise_Favorites: [],
    Scripture_Favorites: []
  },
  gideonCartPageInfo: {
    itemId: '',
    name: 'Cart',
    description: '',
    Label_MerchandiseCardTitle: '',
    Label_ScriptureCardTitle: '',
    Button_ClearCart: '',
    Button_Checkout: '',
    Label_Product: '',
    Label_Item: '',
    Label_Quantity: '',
    Label_Price: '',
    Label_Delete: '',
    Label_CampFundBalance: '',
    Label_Subtotal: 0,
    Label_Page_Title: 'Store',
    Label_Back_Description: '',
    Label_NoRecordsFound: 'No records were found.',
    MerchandiseItems: [],
    ScriptureItems: [],
    CampScriptureFundBalance: 0,
    isAbleToOrderScripture: false
  },
  gideonCheckoutPageInfo: {
    itemId: '',
    name: 'Cart',
    description: '',
    Label_CardMerchandiseTitle: '',
    Label_CardScriptureTitle: '',
    Label_CardSubTitle: '',
    Button_ClearCart: '',
    Button_ContinueShopping: '',
    Button_ReviseOrder: '',
    Label_Item: '',
    Label_Quantity: '',
    Label_Price: '',
    Label_Shipping: '',
    Button_ShippingFind: '',
    Label_ShippingResidential: '',
    Label_ShippingCommercial: '',
    Button_ShippingAddNewAddress: '',
    Label_Billing: '',
    Button_BillingAddNewAddress: '',
    Label_AdditionalInfo: '',
    Label_SpecialInstructions: '',
    Button_SpecialInstructionChange: '',
    Label_ExpeditedProcessing: '',
    Label_ExpeditedProcessingDesc: '',
    Label_CreditCardInfo: '',
    Label_ExpirationDate: '',
    Label_CvvNumber: '',
    Label_WhatsThis: '',
    Label_WhatsThisDescription: '',
    Label_WhatsThisInformation: '',
    Label_OrderTotal: '',
    Button_CompleteOrder: '',
    Default_ShippingMember: '',
    Default_ShippingAddress: '',
    Default_BillingAddress: '',
    PaymentInstruments: null,
    Placeholder_CreditCardNumber: '',
    Placeholder_NameOnCard: '',
    Placeholder_ExpirationMonth: '',
    Placeholder_ExpirationYear: '',
    Placeholder_Cvv: '',
    Label_Page_Title: '',
    Label_Back_Description: '',
    AdditionalInfo: '',
    ExpeditedShipping: {
      IsExpedited: false,
      ExpeditedShippingCode: '',
      ExpeditedShippingCost: 0,
      ExpeditedShippingKey: ''
    },
    Label_RushCharge: '',
    Label_Edit: '',
    Button_Preview: '',
    Label_Items: '',
    Label_Order_Preview: '',
    Label_Order_Preview_Details: '',
    CartItems: [],
    CampScriptureFundBalance: 0,
    ServiceTestamentTotal: 0,
    CampMembers: [],
    CurrentMemberShippingAddresses: [],
    CurrentMembersBillingAddresses: []
  },
  gideonConfirmationPageInfo: {
    itemId: '',
    name: 'Cart',
    description: '',
    Label_CardTitle: '',
    Button_ContinueShopping: '',
    Label_ItemsPurchased: '',
    Label_Item: '',
    Label_Quantity: '',
    Label_Price: '',
    Label_Shipping: '',
    Label_Billing: '',
    Label_CreditCardInfo: '',
    Label_AdditionalInfo: '',
    Label_AdditionalInfoDetail: '',
    Label_OrderTotal: '',
    Button_ItemsInCart: '',
    Label_Page_Title: '',
    Label_Back_Description: '',
    Label_CampScriptureFundBalance: '',
    CampScriptureFundBalance: '',
    EmailSent: false,

    OrderDetailResults: [],
    ShippingName: '',
    ShippingAddressResults: [
      {
        adr_line1: '',
        adr_city: '',
        adr_state: '',
        adr_postal_code: '',
        internalid: 0
      }
    ],
    BillingName: '',
    BillingAddressResults: [
      {
        adr_line1: '',
        adr_city: '',
        adr_state: '',
        adr_postal_code: '',
        internalid: 0
      }
    ],
    Cart: [
      {
        Cart_Total_Products: '0'
      },
      {
        Total_Price: '$0.00'
      }
    ]
  },
  gideonOrderHistoryPageInfo: {
    itemId: '',
    name: 'Order History',
    description: '',
    Label_MerchandiseCardTitle: '',
    Label_ScriptureCardTitle: '',
    Label_OrderID: '',
    Label_Date: '',
    Label_Amount: '',
    Label_Add: '',
    Label_Product: '',
    Label_Quantity: '',
    Label_Price: '',
    Label_Total: '',
    Label_Page_Title: '',
    Label_Back_Description: '',
    Label_NoRecordsFound: '',

    MerchandiseOrders: [],
    ScriptureOrders: []
  },
  gideonSearchPageInfo: {
    itemId: '',
    name: 'Search',
    description: '',
    label_cart_title: 'Search',
    label_product: 'Product',
    label_item: 'Item',
    label_price: 'Price',
    label_type: 'Type',
    label_view: 'View',
    label_page_title: 'Store',
    label_back_description: '',
    label_merchandise_description: 'merchandise',
    label_scripture_description: 'scripture',
    label_NoRecordsFound: 'No records were found.',
    button_search: 'Search'
  },
  gideonSearchProducts: {
    Products: []
  },
  gideonStoreCategories: [
    {
      CategoryName: 'Merchandise',
      Subcategories: []
    },
    {
      CategoryName: 'Scripture',
      Subcategories: []
    }
  ],
  gideonStoreProducts: [],
  gideonStoreSelectedCategory: '',
  gideonStoreSelectedCategoryId: '',
  gideonStoreSelectedSubcategory: '',
  gideonStoreSelectedSubcategoryId: '',
  gideonStoreSelectedProductId: '',
  gideonStoreSelectedProductKey: '',
  gideonStoreSearchText: '',
  gideonStoreCart: [
    {
      Cart_Total_Products: 0
    },
    {
      Total_Price: 0
    }
  ],
  gideonStoreCartCheckoutType: 'merchandise',
  gideonStoreShippingIndKey: '',
  gideonStoreShippingAddresses: [],
  gideonStoreLastCreditCard: {
    name: '',
    number: '',
    expiration: ''
  },
  gideonStoreOrderId: '',
  selectedCreditCard: {},
  selectedShipAddressDisplay: {},
  shippingMemberDisplay: ''
})

export const getters = {
  gideonStoreSiteCoreBaseURL: state => {
    return state.gideonStoreSiteCoreBaseURL
  },
  gideonStoreLandingPageInfo: state => {
    return state.gideonStoreLandingPageInfo
  },
  gideonNavigationPageInfo: state => {
    return state.gideonNavigationPageInfo
  },
  gideonSubcategoryPageInfo: state => {
    return state.gideonSubcategoryPageInfo
  },
  gideonProductDetailPageInfo: state => {
    return state.gideonProductDetailPageInfo
  },
  gideonFavoritesPageInfo: state => {
    return state.gideonFavoritesPageInfo
  },
  gideonCartPageInfo: state => {
    return state.gideonCartPageInfo
  },
  gideonCheckoutPageInfo: state => {
    return state.gideonCheckoutPageInfo
  },
  gideonConfirmationPageInfo: state => {
    return state.gideonConfirmationPageInfo
  },
  gideonOrderHistoryPageInfo: state => {
    return state.gideonOrderHistoryPageInfo
  },
  gideonSearchPageInfo: state => {
    return state.gideonSearchPageInfo
  },
  gideonSearchProducts: state => {
    return state.gideonSearchProducts
  },
  gideonStoreCategories: state => {
    return state.gideonStoreCategories
  },
  gideonStoreProducts: state => {
    return state.gideonStoreProducts
  },
  gideonStoreSelectedCategory: state => {
    return state.gideonStoreSelectedCategory
  },
  gideonStoreSelectedCategoryId: state => {
    return state.gideonStoreSelectedCategoryId
  },
  gideonStoreSelectedSubcategory: state => {
    return state.gideonStoreSelectedSubcategory
  },
  gideonStoreSelectedSubcategoryId: state => {
    return state.gideonStoreSelectedSubcategoryId
  },
  gideonStoreSelectedProductId: state => {
    return state.gideonStoreSelectedProductId
  },
  gideonStoreSelectedProductKey: state => {
    return state.gideonStoreSelectedProductKey
  },
  gideonStoreSearchText: state => {
    return state.gideonStoreSearchText
  },
  gideonStoreCart: state => {
    return state.gideonStoreCart
  },
  gideonStoreCartCheckoutType: state => {
    return state.gideonStoreCartCheckoutType
  },
  gideonStoreShippingAddresses: state => {
    return state.gideonStoreShippingAddresses
  },
  gideonStoreShippingIndKey: state => {
    return state.gideonStoreShippingIndKey
  },
  gideonStoreLastCreditCard: state => {
    return state.gideonStoreLastCreditCard
  },
  gideonStoreOrderId: state => {
    return state.gideonStoreOrderId
  },
  selectedCreditCard: state => {
    return state.selectedCreditCard
  },
  selectedShipAddressDisplay: state => {
    return state.selectedShipAddressDisplay
  },
  shippingMemberDisplay: state => {
    return state.shippingMemberDisplay
  }
}

export const SET_ADDITIONAL_INFO = 'SET_ADDITIONAL_INFO'
export const SET_SELECTED_CREDIT_CARD = 'SET_SELECTED_CREDIT_CARD'
export const SET_SELECTED_SHIPPING_ADDRESS = 'SET_SELECTED_SHIPPING_ADDRESS'
export const SET_SELECTED_SHIPPING_MEMBER = 'SET_SELECTED_SHIPPING_MEMBER'
export const SET_SHIPPING_IS_EXPEDITED = 'SET_SHIPPING_IS_EXPEDITED'
export const SET_STORE_LANDING_PAGE = 'SET_STORE_LANDING_PAGE'
export const SET_STORE_NAVIGATION_PAGE = 'SET_STORE_NAVIGATION_PAGE'
export const SET_STORE_SUBCATEGORY_PAGE = 'SET_STORE_SUBCATEGORY_PAGE'
export const SET_STORE_PRODUCT_DETAIL_PAGE = 'SET_STORE_PRODUCT_DETAIL_PAGE'
export const SET_STORE_FAVORITES_PAGE = 'SET_STORE_FAVORITES_PAGE'
export const SET_STORE_CART_PAGE = 'SET_STORE_CART_PAGE'
export const SET_STORE_CHECKOUT_PAGE = 'SET_STORE_CHECKOUT_PAGE'
export const SET_STORE_CONFIRMATION_PAGE = 'SET_STORE_CONFIRMATION_PAGE'
export const SET_STORE_ORDER_HISTORY_PAGE = 'SET_STORE_ORDER_HISTORY_PAGE'
export const SET_STORE_SEARCH_PAGE = 'SET_STORE_SEARCH_PAGE'
export const SET_STORE_PRODUCT_SEARCH = 'SET_STORE_PRODUCT_SEARCH'
export const SET_STORE_SHIPPING_ADDRESS = 'SET_STORE_SHIPPING_ADDRESS'
export const SET_STORE_SELECTED_CATEGORY = 'SET_STORE_SELECTED_CATEGORY'
export const SET_STORE_SELECTED_CATEGORY_ID = 'SET_STORE_SELECTED_CATEGORY_ID'
export const SET_STORE_SELECTED_SUBCATEGORY = 'SET_STORE_SELECTED_SUBCATEGORY'
export const SET_STORE_SELECTED_SUBCATEGORY_ID = 'SET_STORE_SELECTED_SUBCATEGORY_ID'
export const SET_STORE_SELECTED_PRODUCT_ID = 'SET_STORE_SELECTED_PRODUCT_ID'
export const SET_STORE_SELECTED_PRODUCT_KEY = 'SET_STORE_SELECTED_PRODUCT_KEY'
export const SET_STORE_SEARCHED_TEXT = 'SET_STORE_SEARCHED_TEXT'
export const SET_STORE_CART_CHECKOUT_TYPE = 'SET_STORE_CART_CHECKOUT_TYPE'
export const SET_STORE_LAST_CREDIT_CARD = 'SET_STORE_LAST_CREDIT_CARD'
export const SET_STORE_SHIPPING_IND_KEY = 'SET_STORE_SHIPPING_IND_KEY'
export const SET_STORE_ORDER_ID = 'SET_STORE_ORDER_ID'

export const mutations = {
  [SET_STORE_LANDING_PAGE](state, data) {
    ; (state.gideonStoreLandingPageInfo.name = data.IndexPage.Name),
      (state.gideonStoreLandingPageInfo.text = data.IndexPage.Description),
      (state.gideonStoreLandingPageInfo.buttonScripture = data.IndexPage.Button_Scripture),
      (state.gideonStoreLandingPageInfo.buttonMerchandise = data.IndexPage.Button_Merchandise),
      (state.gideonStoreLandingPageInfo.rotatorImages = data.RotatorImages),
      (state.gideonStoreLandingPageInfo.label_page_title = data.IndexPage.Label_Page_Title),
      (state.gideonStoreLandingPageInfo.CampScriptureFundBalance = data.CampScriptureFundBalance),
      (state.gideonStoreLandingPageInfo.Label_CampFundBalance = data.IndexPage.Label_CampFundBalance),
      (state.gideonStoreCategories = data.Categories)
  },
  [SET_STORE_NAVIGATION_PAGE](state, data) {
    ; (state.gideonNavigationPageInfo.name = data.IndexPage.Name),
      (state.gideonNavigationPageInfo.information = data.IndexPage.Information),
      (state.gideonNavigationPageInfo.buttonBack = data.IndexPage.Button_Back),
      (state.gideonNavigationPageInfo.buttonSearch = data.IndexPage.Button_Search),
      (state.gideonNavigationPageInfo.buttonFavorites = data.IndexPage.Button_Favorites),
      (state.gideonNavigationPageInfo.buttonMyOrder = data.IndexPage.Button_MyOrder),
      (state.gideonNavigationPageInfo.buttonCart = data.IndexPage.Button_Cart),
      (state.gideonNavigationPageInfo.labelMerchTotal = data.IndexPage.Label_MerchTotal),
      (state.gideonStoreCart = data.Cart)
  },
  [SET_STORE_SUBCATEGORY_PAGE](state, data) {
    ; (state.gideonSubcategoryPageInfo.itemId = data.SubCategoryPage.ItemID),
      (state.gideonSubcategoryPageInfo.name = data.SubCategoryPage.category_name),
      (state.gideonSubcategoryPageInfo.ptc_key = data.SubCategoryPage.ptc_key),
      (state.gideonSubcategoryPageInfo.description = data.SubCategoryPage.category_description),
      (state.gideonSubcategoryPageInfo.category_class = data.SubCategoryPage.category_class),
      (state.gideonSubcategoryPageInfo.category_icon_name = data.SubCategoryPage.category_icon_name),
      (state.gideonSubcategoryPageInfo.button_view_more = data.SubCategoryPage.button_view_more),
      (state.gideonSubcategoryPageInfo.button_go = data.SubCategoryPage.button_go),
      (state.gideonSubcategoryPageInfo.label_product_not_available = data.SubCategoryPage.label_product_not_available),
      (state.gideonSubcategoryPageInfo.label_item = data.SubCategoryPage.label_item),
      (state.gideonSubcategoryPageInfo.label_price = data.SubCategoryPage.label_price),
      (state.gideonSubcategoryPageInfo.label_back_description = data.SubCategoryPage.label_back_description),
      (state.gideonSubcategoryPageInfo.label_page_title = data.SubCategoryPage.label_page_title),
      (state.gideonStoreProducts = data.SubCategoryPage.ProductListing)
    state.gideonStoreCategories = data.Categories
  },
  [SET_STORE_PRODUCT_DETAIL_PAGE](state, data) {
    // Page Info
    ; (state.gideonProductDetailPageInfo.itemId = data.ProductDetailPage.ItemID),
      (state.gideonProductDetailPageInfo.name = data.ProductDetailPage.Name),
      (state.gideonProductDetailPageInfo.description = data.ProductDetailPage.Description),
      (state.gideonProductDetailPageInfo.label_quantity = data.ProductDetailPage.Label_Quantity),
      (state.gideonProductDetailPageInfo.cart_quantity = data.CartQuantity),
      (state.gideonProductDetailPageInfo.button_AddToCart = data.ProductDetailPage.Button_AddToCart),
      (state.gideonProductDetailPageInfo.button_Go = data.ProductDetailPage.Button_Go),
      (state.gideonProductDetailPageInfo.button_AddToFavorites = data.ProductDetailPage.Button_AddToFavorites),
      (state.gideonProductDetailPageInfo.label_item = data.ProductDetailPage.Label_Item),
      (state.gideonProductDetailPageInfo.label_price = data.ProductDetailPage.Label_Price),
      (state.gideonProductDetailPageInfo.label_OtherProduct_Title = data.ProductDetailPage.Label_OtherProduct_Title),
      (state.gideonProductDetailPageInfo.label_page_title = data.ProductDetailPage.Label_Page_Title),
      (state.gideonProductDetailPageInfo.label_back_description = data.ProductDetailPage.Label_Back_Description),
      // Product Info
      (state.gideonProductDetailPageInfo.Product.itemId = data.Product.ItemID)
    state.gideonProductDetailPageInfo.Product.prd_key = data.Product.prd_key
    state.gideonProductDetailPageInfo.Product.prd_code = data.Product.prd_code
    state.gideonProductDetailPageInfo.Product.prd_name = data.Product.prd_name
    state.gideonProductDetailPageInfo.Product.prd_store_description = data.Product.prd_store_description
    state.gideonProductDetailPageInfo.Product.prd_image_file_path = data.Product.prd_image_file_path
    state.gideonProductDetailPageInfo.Product.prd_detail_image_file_path1 = data.Product.prd_detail_image_file_path1
    state.gideonProductDetailPageInfo.Product.prd_detail_image_file_path2 = data.Product.prd_detail_image_file_path2
    state.gideonProductDetailPageInfo.Product.prd_display_price = data.Product.prd_display_price
    state.gideonProductDetailPageInfo.Product.prd_show_out_of_stock = data.Product.prd_show_out_of_stock

      // Subcategory Info
      ; (state.gideonProductDetailPageInfo.Subcategory.itemId = data.SubCategory.ItemID),
        (state.gideonProductDetailPageInfo.Subcategory.ptc_key = data.SubCategory.ptc_key),
        (state.gideonProductDetailPageInfo.Subcategory.category_name = data.SubCategory.category_name),
        //Other Products
        (state.gideonProductDetailPageInfo.OtherProducts = data.OtherProducts),
        // Product Listing
        (state.gideonStoreProducts = data.SubCategory.ProductListing)
  },
  [SET_STORE_FAVORITES_PAGE](state, data) {
    // Page Info
    state.gideonFavoritesPageInfo.itemId = data.FavoritesPage.ItemID
    state.gideonFavoritesPageInfo.name = data.FavoritesPage.Name
    state.gideonFavoritesPageInfo.description = data.FavoritesPage.Description
    state.gideonFavoritesPageInfo.label_cart_title = data.FavoritesPage.Label_CardTitle
    state.gideonFavoritesPageInfo.label_product = data.FavoritesPage.Label_Product
    state.gideonFavoritesPageInfo.label_item = data.FavoritesPage.Label_Item
    state.gideonFavoritesPageInfo.label_price = data.FavoritesPage.Label_Price
    state.gideonFavoritesPageInfo.label_type = data.FavoritesPage.Label_Type
    state.gideonFavoritesPageInfo.label_add = data.FavoritesPage.Label_Add
    state.gideonFavoritesPageInfo.label_page_title = data.FavoritesPage.Label_Page_Title
    state.gideonFavoritesPageInfo.label_merchandise_description = data.FavoritesPage.Label_Merchandise_Description
    state.gideonFavoritesPageInfo.label_scripture_description = data.FavoritesPage.Label_Scripture_Description
    state.gideonFavoritesPageInfo.label_back_description = data.FavoritesPage.Label_Back_Description
    state.gideonFavoritesPageInfo.label_NoRecordsFound = data.FavoritesPage.label_NoRecordsFound
    state.gideonFavoritesPageInfo.Merchandise_Favorites = data.Merchandise_Favorites
    state.gideonFavoritesPageInfo.Scripture_Favorites = data.Scripture_Favorites
  },
  [SET_STORE_CART_PAGE](state, data) {
    // Page Info
    state.gideonCartPageInfo.itemId = data.CartPage.ItemID
    state.gideonCartPageInfo.name = data.CartPage.Name
    state.gideonCartPageInfo.description = data.CartPage.Description
    state.gideonCartPageInfo.Label_MerchandiseCardTitle = data.CartPage.Label_MerchandiseCardTitle
    state.gideonCartPageInfo.Label_ScriptureCardTitle = data.CartPage.Label_ScriptureCardTitle
    state.gideonCartPageInfo.Button_ClearCart = data.CartPage.Button_ClearCart
    state.gideonCartPageInfo.Button_Checkout = data.CartPage.Button_Checkout
    state.gideonCartPageInfo.Label_Product = data.CartPage.Label_Product
    state.gideonCartPageInfo.Label_Item = data.CartPage.Label_Item
    state.gideonCartPageInfo.Label_Quantity = data.CartPage.Label_Quantity
    state.gideonCartPageInfo.Label_Price = data.CartPage.Label_Price
    state.gideonCartPageInfo.Label_Delete = data.CartPage.Label_Delete
    state.gideonCartPageInfo.Label_CampFundBalance = data.CartPage.Label_CampFundBalance
    state.gideonCartPageInfo.Label_Subtotal = data.CartPage.Label_Subtotal
    state.gideonCartPageInfo.Label_Page_Title = data.CartPage.Label_Page_Title
    state.gideonCartPageInfo.Label_Back_Description = data.CartPage.Label_Back_Description
    state.gideonCartPageInfo.Label_NoRecordsFound = data.CartPage.Label_NoRecordsFound
    state.gideonCartPageInfo.Label_ExceededCSFB = data.CartPage.Label_ExceededCSFB
    state.gideonCartPageInfo.Label_ServiceTestamentTotal = data.CartPage.Label_ServiceTestamentTotal
    state.gideonCartPageInfo.ServiceTestamentTotal = data.ServiceTestamentTotal
    state.gideonCartPageInfo.CampScriptureFundBalance = data.CampScriptureFundBalance
    state.gideonCartPageInfo.isAbleToOrderScripture = data.isAbleToOrderScripture

    state.gideonCartPageInfo.MerchandiseItems = data.MerchandiseItems
    state.gideonCartPageInfo.ScriptureItems = data.ScriptureItems
  },
  [SET_STORE_CHECKOUT_PAGE](state, data) {
    // Page Info
    state.gideonCheckoutPageInfo.itemId = data.CheckoutPage.ItemID
    state.gideonCheckoutPageInfo.name = data.CheckoutPage.Name
    state.gideonCheckoutPageInfo.description = data.CheckoutPage.Description
    state.gideonCheckoutPageInfo.Label_CardMerchandiseTitle = data.CheckoutPage.Label_CardMerchandiseTitle
    state.gideonCheckoutPageInfo.Label_CardScriptureTitle = data.CheckoutPage.Label_CardScriptureTitle
    state.gideonCheckoutPageInfo.Label_CardSubTitle = data.CheckoutPage.Label_CardSubTitle
    state.gideonCheckoutPageInfo.Button_ClearCart = data.CheckoutPage.Button_ClearCart
    state.gideonCheckoutPageInfo.Button_ContinueShopping = data.CheckoutPage.Button_ContinueShopping
    state.gideonCheckoutPageInfo.Button_ReviseOrder = data.CheckoutPage.Button_ReviseOrder
    state.gideonCheckoutPageInfo.Label_Item = data.CheckoutPage.Label_Item
    state.gideonCheckoutPageInfo.Label_Quantity = data.CheckoutPage.Label_Quantity
    state.gideonCheckoutPageInfo.Label_Price = data.CheckoutPage.Label_Price
    state.gideonCheckoutPageInfo.Label_Shipping = data.CheckoutPage.Label_Shipping
    state.gideonCheckoutPageInfo.Button_ShippingFind = data.CheckoutPage.Button_ShippingFind
    state.gideonCheckoutPageInfo.Label_ShippingResidential = data.CheckoutPage.Label_ShippingResidential
    state.gideonCheckoutPageInfo.Label_ShippingCommercial = data.CheckoutPage.Label_ShippingCommercial
    state.gideonCheckoutPageInfo.Button_ShippingAddNewAddress = data.CheckoutPage.Button_ShippingAddNewAddress
    state.gideonCheckoutPageInfo.Label_Billing = data.CheckoutPage.Label_BIlling
    state.gideonCheckoutPageInfo.Button_BillingAddNewAddress = data.CheckoutPage.Button_BillingAddNewAddress
    state.gideonCheckoutPageInfo.Label_AdditionalInfo = data.CheckoutPage.Label_AdditionalInfo
    state.gideonCheckoutPageInfo.Label_SpecialInstructions = data.CheckoutPage.Label_SpecialInstructions
    state.gideonCheckoutPageInfo.Button_SpecialInstructionChange = data.CheckoutPage.Button_SpecialInstructionChange
    state.gideonCheckoutPageInfo.Label_ExpeditedProcessing = data.CheckoutPage.Label_ExpeditedProcessing
    state.gideonCheckoutPageInfo.Label_ExpeditedProcessingDesc = data.CheckoutPage.Label_ExpeditedProcessingDesc
    state.gideonCheckoutPageInfo.Label_CreditCardInfo = data.CheckoutPage.Label_CreditCardInfo
    state.gideonCheckoutPageInfo.Label_ExpirationDate = data.CheckoutPage.Label_ExpirationDate
    state.gideonCheckoutPageInfo.Label_CvvNumber = data.CheckoutPage.Label_CvvNumber
    state.gideonCheckoutPageInfo.Label_WhatsThis = data.CheckoutPage.Label_WhatsThis
    state.gideonCheckoutPageInfo.Label_WhatsThisDescription = data.CheckoutPage.Label_WhatsThisDescription
    state.gideonCheckoutPageInfo.Label_WhatsThisInformation = data.CheckoutPage.Label_WhatsThisInformation
    state.gideonCheckoutPageInfo.Label_OrderTotal = data.CheckoutPage.Label_OrderTotal
    state.gideonCheckoutPageInfo.Button_CompleteOrder = data.CheckoutPage.Button_CompleteOrder
    state.gideonCheckoutPageInfo.Default_ShippingMember = data.CheckoutPage.Default_ShippingMember
    state.gideonCheckoutPageInfo.Default_ShippingAddress = data.CheckoutPage.Default_ShippingAddress
    state.gideonCheckoutPageInfo.Default_BillingAddress = data.CheckoutPage.Default_BillingAddress
    state.gideonCheckoutPageInfo.Placeholder_CreditCardNumber = data.CheckoutPage.Placeholder_CreditCardNumber
    state.gideonCheckoutPageInfo.Placeholder_NameOnCard = data.CheckoutPage.Placeholder_NameOnCard
    state.gideonCheckoutPageInfo.Placeholder_ExpirationMonth = data.CheckoutPage.Placeholder_ExpirationMonth
    state.gideonCheckoutPageInfo.Placeholder_ExpirationYear = data.CheckoutPage.Placeholder_ExpirationYear
    state.gideonCheckoutPageInfo.Placeholder_Cvv = data.CheckoutPage.Placeholder_Cvv
    state.gideonCheckoutPageInfo.Label_Page_Title = data.CheckoutPage.Label_Page_Title
    state.gideonCheckoutPageInfo.Label_Back_Description = data.CheckoutPage.Label_Back_Description
    state.gideonCheckoutPageInfo.Label_CampFundBalance = data.CheckoutPage.Label_CampFundBalance
    state.gideonCheckoutPageInfo.Label_ServiceTestamentTotal = data.CheckoutPage.Label_ServiceTestamentTotal
    state.gideonCheckoutPageInfo.Label_Edit = data.CheckoutPage.Label_Edit
    state.gideonCheckoutPageInfo.Button_Preview = data.CheckoutPage.Button_Preview
    state.gideonCheckoutPageInfo.Label_Items = data.CheckoutPage.Label_Items
    state.gideonCheckoutPageInfo.Label_Order_Preview = data.CheckoutPage.Label_Order_Preview
    state.gideonCheckoutPageInfo.Label_Order_Preview_Details = data.CheckoutPage.Label_Order_Preview_Details
    state.gideonCheckoutPageInfo.Label_RushCharge = data.CheckoutPage.Label_Rush_Charge
    state.gideonCheckoutPageInfo.ExpeditedShipping = data.ExpeditedShipping
    state.gideonCheckoutPageInfo.CartItems = data.CartItems
    state.gideonCheckoutPageInfo.CampScriptureFundBalance = data.CampScriptureFundBalance
    state.gideonCheckoutPageInfo.ServiceTestamentTotal = data.ServiceTestamentTotal
    state.gideonCheckoutPageInfo.CampMembers = data.CampMembers
    state.gideonCheckoutPageInfo.CurrentMemberShippingAddresses = data.CurrentMemberShippingAddresses
    state.gideonCheckoutPageInfo.CurrentMembersBillingAddresses = data.CurrentMembersBillingAddresses
    state.gideonCheckoutPageInfo.PaymentInstruments = data.PaymentInstruments
  },
  [SET_STORE_CONFIRMATION_PAGE](state, data) {
    // Page Info
    state.gideonConfirmationPageInfo.itemId = data.PurchaseConfirmationPage.ItemID
    state.gideonConfirmationPageInfo.name = data.PurchaseConfirmationPage.Name
    state.gideonConfirmationPageInfo.description = data.PurchaseConfirmationPage.Description
    state.gideonConfirmationPageInfo.Label_CardMerchandiseTitle =
      data.PurchaseConfirmationPage.Label_CardMerchandiseTitle
    state.gideonConfirmationPageInfo.Label_CardScriptureTitle = data.PurchaseConfirmationPage.Label_CardScriptureTitle
    state.gideonConfirmationPageInfo.Button_ContinueShopping = data.PurchaseConfirmationPage.Button_ContinueShopping
    state.gideonConfirmationPageInfo.Label_ItemsPurchased = data.PurchaseConfirmationPage.Label_ItemsPurchased
    state.gideonConfirmationPageInfo.Label_Item = data.PurchaseConfirmationPage.Label_Item
    state.gideonConfirmationPageInfo.Label_Quantity = data.PurchaseConfirmationPage.Label_Quantity
    state.gideonConfirmationPageInfo.Label_Price = data.PurchaseConfirmationPage.Label_Price
    state.gideonConfirmationPageInfo.Label_Shipping = data.PurchaseConfirmationPage.Label_Shipping
    state.gideonConfirmationPageInfo.Label_Billing = data.PurchaseConfirmationPage.Label_Billing
    state.gideonConfirmationPageInfo.Label_CreditCardInfo = data.PurchaseConfirmationPage.Label_CreditCardInfo
    state.gideonConfirmationPageInfo.Label_AdditionalInfo = data.PurchaseConfirmationPage.Label_AdditionalInfo
    state.gideonConfirmationPageInfo.Label_AdditionalInfoDetail =
      data.PurchaseConfirmationPage.Label_AdditionalInfoDetail
    state.gideonConfirmationPageInfo.Label_OrderTotal = data.PurchaseConfirmationPage.Label_OrderTotal
    state.gideonConfirmationPageInfo.Button_ItemsInCart = data.PurchaseConfirmationPage.Button_ItemsInCart
    state.gideonConfirmationPageInfo.Label_Page_Title = data.PurchaseConfirmationPage.Label_Page_Title
    state.gideonConfirmationPageInfo.Label_Back_Description = data.PurchaseConfirmationPage.Label_Back_Description
    state.gideonConfirmationPageInfo.Label_CampScriptureFundBalance =
      data.PurchaseConfirmationPage.Label_CampScriptureFundBalance
    state.gideonConfirmationPageInfo.CampScriptureFundBalance = data.CampScriptureFundBalance

    state.gideonConfirmationPageInfo.OrderDetailResults = data.OrderDetailResults
    state.gideonConfirmationPageInfo.ShippingName = data.ShippingName
    state.gideonConfirmationPageInfo.ShippingAddressResults = data.ShippingAddressResults
    state.gideonConfirmationPageInfo.BillingName = data.BillingName
    state.gideonConfirmationPageInfo.BillingAddressResults = data.BillingAddressResults
    state.gideonConfirmationPageInfo.Cart = data.Cart
    state.gideonConfirmationPageInfo.EmailSent = data.EmailSent
  },
  [SET_STORE_ORDER_HISTORY_PAGE](state, data) {
    // Page Info
    state.gideonOrderHistoryPageInfo.itemId = data.OrderHistoryPage.ItemID
    state.gideonOrderHistoryPageInfo.name = data.OrderHistoryPage.Name
    state.gideonOrderHistoryPageInfo.description = data.OrderHistoryPage.Description
    state.gideonOrderHistoryPageInfo.Label_MerchandiseCardTitle = data.OrderHistoryPage.Label_MerchandiseCardTitle
    state.gideonOrderHistoryPageInfo.Label_ScriptureCardTitle = data.OrderHistoryPage.Label_ScriptureCardTitle
    state.gideonOrderHistoryPageInfo.Label_OrderID = data.OrderHistoryPage.Label_OrderID
    state.gideonOrderHistoryPageInfo.Label_Date = data.OrderHistoryPage.Label_Date
    state.gideonOrderHistoryPageInfo.Label_Amount = data.OrderHistoryPage.Label_Amount
    state.gideonOrderHistoryPageInfo.Label_Add = data.OrderHistoryPage.Label_Add
    state.gideonOrderHistoryPageInfo.Label_Product = data.OrderHistoryPage.Label_Product
    state.gideonOrderHistoryPageInfo.Label_Quantity = data.OrderHistoryPage.Label_Quantity
    state.gideonOrderHistoryPageInfo.Label_Price = data.OrderHistoryPage.Label_Price
    state.gideonOrderHistoryPageInfo.Label_Total = data.OrderHistoryPage.Label_Total
    state.gideonOrderHistoryPageInfo.Label_Page_Title = data.OrderHistoryPage.Label_Page_Title
    state.gideonOrderHistoryPageInfo.Label_Back_Description = data.OrderHistoryPage.Label_Back_Description
    state.gideonOrderHistoryPageInfo.Label_NoRecordsFound = data.OrderHistoryPage.Label_NoRecordsFound
    state.gideonOrderHistoryPageInfo.MerchandiseOrders = data.MerchandiseOrders
    state.gideonOrderHistoryPageInfo.ScriptureOrders = data.ScriptureOrders
  },
  [SET_STORE_SEARCH_PAGE](state, data) {
    state.gideonSearchPageInfo.itemId = data.SearchPage.ItemID
    state.gideonSearchPageInfo.name = data.SearchPage.Name
    state.gideonSearchPageInfo.description = data.SearchPage.Description
    state.gideonSearchPageInfo.label_cart_title = data.SearchPage.Label_CardTitle
    state.gideonSearchPageInfo.label_product = data.SearchPage.Label_Product
    state.gideonSearchPageInfo.label_item = data.SearchPage.Label_Item
    state.gideonSearchPageInfo.label_price = data.SearchPage.Label_Price
    state.gideonSearchPageInfo.label_type = data.SearchPage.Label_Type
    state.gideonSearchPageInfo.label_view = data.SearchPage.Label_View
    state.gideonSearchPageInfo.label_page_title = data.SearchPage.Label_Page_Title
    state.gideonSearchPageInfo.label_back_description = data.SearchPage.Label_Back_Description
    state.gideonSearchPageInfo.label_merchandise_description = data.SearchPage.Label_Merchandise_Description
    state.gideonSearchPageInfo.label_scripture_description = data.SearchPage.Label_Scripture_Description
    state.gideonSearchPageInfo.label_NoRecordsFound = data.SearchPage.Label_NoRecordsFound
    state.gideonSearchPageInfo.button_search = data.SearchPage.Button_Search
  },
  [SET_STORE_PRODUCT_SEARCH](state, data) {
    state.gideonSearchProducts.Products = data.Products
  },
  [SET_STORE_SHIPPING_ADDRESS](state, data) {
    state.gideonCheckoutPageInfo.CurrentMemberShippingAddresses = data.MemberShippingAddresses
  },
  [SET_STORE_SHIPPING_IND_KEY](state, data) {
    state.gideonStoreShippingIndKey = data
  },
  [SET_STORE_SELECTED_CATEGORY](state, data) {
    state.gideonStoreSelectedCategory = data
  },
  [SET_STORE_SELECTED_CATEGORY_ID](state, data) {
    state.gideonStoreSelectedCategoryId = data
  },
  [SET_STORE_SELECTED_SUBCATEGORY](state, data) {
    state.gideonStoreSelectedSubcategory = data
  },
  [SET_STORE_SELECTED_SUBCATEGORY_ID](state, data) {
    state.gideonStoreSelectedSubcategoryId = data
  },
  [SET_STORE_SELECTED_PRODUCT_ID](state, data) {
    state.gideonStoreSelectedProductId = data
  },
  [SET_STORE_SELECTED_PRODUCT_KEY](state, data) {
    state.gideonStoreSelectedProductKey = data
  },
  [SET_STORE_CART_CHECKOUT_TYPE](state, data) {
    state.gideonStoreCartCheckoutType = data
  },
  [SET_STORE_SEARCHED_TEXT](state, data) {
    state.gideonStoreSearchText = data
  },
  [SET_STORE_LAST_CREDIT_CARD](state, data) {
    state.gideonStoreLastCreditCard = data
  },
  [SET_SELECTED_SHIPPING_MEMBER](state, data) {
    state.shippingMemberDisplay = data
  },
  [SET_SELECTED_SHIPPING_ADDRESS](state, data) {
    state.selectedShipAddressDisplay = data
  },
  [SET_SELECTED_CREDIT_CARD](state, data) {
    state.selectedCreditCard = data
  },
  [SET_STORE_ORDER_ID](state, data) {
    state.gideonStoreOrderId = data
  },
  [SET_SHIPPING_IS_EXPEDITED](state, data) {
    state.gideonCheckoutPageInfo.ExpeditedShipping.IsExpedited = data
  },
  [SET_ADDITIONAL_INFO](state, data) {
    state.gideonCheckoutPageInfo.AdditionalInfo = data
  }
}

export const actions = {
  async loadStoreLandingPage({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreLandingPage(payload)
      if (response && response.status === 200) {
        await commit('SET_STORE_LANDING_PAGE', response.data)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreNavigationPage({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreNavigationPage(
        payload.lang,
        rootGetters['user/userLogin'].indkey,
        payload.landingpage
      )
      if (response && response.status === 200) {
        await commit('SET_STORE_NAVIGATION_PAGE', response.data)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreSubcategoryPage({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreSubcategoryPage(payload.lang, payload.subcategoryid)

      if (response && response.status === 200) {
        await commit('SET_STORE_SUBCATEGORY_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreProductDetailPage({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreProductDetailPage(
        payload.lang,
        payload.prd_key,
        rootGetters['user/userLogin'].indkey,
        payload.landingpage
      )

      if (response && response.status === 200) {
        await commit('SET_STORE_PRODUCT_DETAIL_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreFavoritesPage({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreFavoritesPage(
        payload.lang,
        rootGetters['user/userLogin'].indkey,
        payload.landingpage
      )

      if (response && response.status === 200) {
        await commit('SET_STORE_FAVORITES_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreCartPage({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreCartPage(
        payload.lang,
        rootGetters['user/userLogin'].indkey,
        payload.cmp_key,
        payload.landingpage
      )

      if (response && response.status === 200) {
        await commit('SET_STORE_CART_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreCheckout({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreCheckout(
        payload.lang,
        rootGetters['user/userLogin'].indkey,
        payload.org_key,
        payload.landingpage,
        payload.product_type
      )

      if (response && response.status === 200) {
        await commit('SET_STORE_CHECKOUT_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreConfirmation({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreConfirmation(
        payload.lang,
        rootGetters['user/userLogin'].indkey,
        payload.landingpage,
        payload.product_type,
        payload.orderid
      )

      if (response && response.status === 200) {
        await commit('SET_STORE_CONFIRMATION_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreOrderHistory({ commit, rootGetters }, payload) {
    try {
      let response = await apiCalls.loadStoreOrderHistory(
        payload.lang,
        rootGetters['user/userLogin'].indkey,
        payload.landingpage,
        payload.org_key
      )

      if (response && response.status === 200) {
        await commit('SET_STORE_ORDER_HISTORY_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreSearchPage({ commit }, payload) {
    try {
      let response = await apiCalls.loadStoreSearchPage(payload.lang, payload.landingpage)

      if (response && response.status === 200) {
        await commit('SET_STORE_SEARCH_PAGE', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreProductSearch({ commit }, payload) {
    try {
      let response = await apiCalls.loadStoreProductSearch(payload.lang, payload.criteria, payload.product_type)

      if (response && response.status === 200) {
        await commit('SET_STORE_PRODUCT_SEARCH', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async loadStoreCheckoutShippingAddress({ commit, rootGetters }, ind_key) {
    try {
      let response = await apiCalls.loadStoreCheckoutShippingAddress(ind_key, rootGetters['user/userLogin'].indkey)

      if (response && response.status === 200) {
        await commit('SET_STORE_SHIPPING_ADDRESS', response.data)
        return true
      }
      throw 'Did not return records'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  setStoreSelectedCategory: ({ commit }, payload) => {
    commit('SET_STORE_SELECTED_CATEGORY', payload)
  },
  setStoreSelectedCategoryId: ({ commit }, payload) => {
    commit('SET_STORE_SELECTED_CATEGORY_ID', payload)
  },
  setStoreShippingIndKey: ({ commit }, payload) => {
    commit('SET_STORE_SHIPPING_IND_KEY', payload)
  },
  setStoreSelectedSubCategory: ({ commit }, payload) => {
    commit('SET_STORE_SELECTED_SUBCATEGORY', payload)
  },
  setStoreSelectedSubCategoryId: ({ commit }, payload) => {
    commit('SET_STORE_SELECTED_SUBCATEGORY_ID', payload)
  },
  setStoreSelectedProductId: ({ commit }, payload) => {
    commit('SET_STORE_SELECTED_PRODUCT_ID', payload)
  },
  setStoreShippingIsExpedited: ({ commit }, payload) => {
    commit('SET_SHIPPING_IS_EXPEDITED', payload)
  },
  setStoreSelectedProductKey: ({ commit }, payload) => {
    commit('SET_STORE_SELECTED_PRODUCT_KEY', payload)
  },
  setStoreCartCheckoutType: ({ commit }, payload) => {
    commit('SET_STORE_CART_CHECKOUT_TYPE', payload)
  },
  setStoreSearchText: ({ commit }, payload) => {
    commit('SET_STORE_SEARCHED_TEXT', payload)
  },
  async updateStoreCart({ rootGetters }, payload) {
    try {
      let response = await apiCalls.updateStoreCart(
        rootGetters['user/userLogin'].indkey,
        payload.prd_key,
        payload.quantity
      )
      return !!response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async clearStoreCart({ rootGetters }, product_type) {
    try {
      let response = await apiCalls.clearStoreCart(rootGetters['user/userLogin'].indkey, product_type)
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async updateStoreFavorites({ rootGetters }, payload) {
    try {
      let response = await apiCalls.updateStoreFavorites(
        rootGetters['user/userLogin'].indkey,
        payload.prd_key,
        payload.isfavorite
      )
      return response && response.status === 200
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async addStorePurchase({ commit }, payload) {
    try {
      let response = await apiCalls.addStorePurchase(payload)
      if (response && response.status === 200) {
        commit('SET_ADDITIONAL_INFO', payload.AdditionalInfo)
        commit('SET_SELECTED_CREDIT_CARD', payload.selectedCreditCard)
        commit('SET_SELECTED_SHIPPING_ADDRESS', payload.selectedShipAddressDisplay)
        commit('SET_SELECTED_SHIPPING_MEMBER', payload.shippingMemberDisplay)
        return response
      }
      throw 'There was an issue processing order.'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  async removePaymentInstrument({ commit }, piid) {
    try {
      let response = await apiCalls.removePaymentInstrument(piid)
      if (response && response.status === 200) {
        return true
      }
      throw 'An error occurred while attempting the removal of a payment instrument.'
    } catch (error) {
      console.error(error)
      return false
    }
  },
  setStoreLastCreditCard: ({ commit }, payload) => {
    commit('SET_STORE_LAST_CREDIT_CARD', payload)
  },
  setStoreOrderId: ({ commit }, payload) => {
    commit('SET_STORE_ORDER_ID', payload)
  }
}

const apiCalls = {
  loadStoreLandingPage(payload) {
    const route = GIDEON_STORE_API_CONFIG.getStoreLandingPage(payload.lang, payload.cmp_key, payload.landingpage)
    return axios.get(route)
  },
  loadStoreNavigationPage(lang, ind_key, landingpage) {
    const route = GIDEON_STORE_API_CONFIG.getStoreNavigationPage(lang, ind_key, landingpage)
    return axios.get(route)
  },
  loadStoreSubcategoryPage(lang, subcatid) {
    const route = GIDEON_STORE_API_CONFIG.getStoreSubcategoryPage(lang, subcatid)
    return axios.get(route)
  },
  loadStoreProductDetailPage(lang, prd_key, ind_key, landingpage) {
    const route = GIDEON_STORE_API_CONFIG.getStoreProductDetailPage(lang, prd_key, ind_key, landingpage)
    return axios.get(route)
  },
  loadStoreFavoritesPage(lang, ind_key, landingpage) {
    const route = GIDEON_STORE_API_CONFIG.getStoreFavorites(lang, ind_key, landingpage)
    return axios.get(route)
  },
  loadStoreCartPage(lang, ind_key, cmp_key, landingpage) {
    const route = GIDEON_STORE_API_CONFIG.getStoreCart(lang, ind_key, cmp_key, landingpage)
    return axios.get(route)
  },
  loadStoreCheckout(lang, ind_key, org_key, landingpage, product_type) {
    const route = GIDEON_STORE_API_CONFIG.getStoreCheckout(lang, ind_key, org_key, landingpage, product_type)
    return axios.get(route)
  },
  loadStoreConfirmation(lang, ind_key, landingpage, product_type, orderid) {
    const route = GIDEON_STORE_API_CONFIG.getStoreConfirmation(lang, ind_key, landingpage, product_type, orderid)
    return axios.get(route)
  },
  loadStoreOrderHistory(lang, ind_key, landingpage, org_key) {
    const route = GIDEON_STORE_API_CONFIG.getStoreOrderHistory(lang, ind_key, landingpage, org_key)
    return axios.get(route)
  },
  loadStoreSearchPage(lang, landingpage) {
    const route = GIDEON_STORE_API_CONFIG.getStoreSearch(lang, landingpage)
    return axios.get(route)
  },
  loadStoreProductSearch(lang, criteria, product_type) {
    const route = GIDEON_STORE_API_CONFIG.getStoreProductSearch(lang, criteria, product_type)
    return axios.get(route)
  },
  loadStoreCheckoutShippingAddress(ind_key, current_ind_key) {
    const route = GIDEON_STORE_API_CONFIG.getStoreCheckoutShippingAddress(ind_key, current_ind_key)
    return axios.get(route)
  },
  updateStoreCart(ind_key, prd_key, quantity) {
    const route = GIDEON_STORE_API_CONFIG.updateStoreCart(ind_key, prd_key, quantity)
    return axios.get(route)
  },
  clearStoreCart(ind_key, product_type) {
    const route = GIDEON_STORE_API_CONFIG.clearStoreCart(ind_key, product_type)
    return axios.get(route)
  },
  updateStoreFavorites(ind_key, prd_key, isfavorite) {
    const route = GIDEON_STORE_API_CONFIG.updateStoreFavorites(ind_key, prd_key, isfavorite)
    return axios.get(route)
  },
  addStorePurchase(order) {
    const route = GIDEON_STORE_API_CONFIG.addStorePurchase()
    return axios.post(route, order)
  },
  removePaymentInstrument(piid) {
    const route = GIDEON_STORE_API_CONFIG.removePaymentInstrument(piid)
    return axios.get(route)
  }
}

const reportCalls = {}

export const gideonStore = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
